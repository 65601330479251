import React, { useEffect, useState} from 'react';
import './App.scss';

import {
    isInTimeRange,
    randomize,
    getToday,
    arrayIsEmpty,
    objectIsEmpty,
    startOfDay,
    endOfDay,
} from './data';

import {buildURL, parseSheetsData} from "./GoogleSheetsAPI";

import SlideShow from './components/SlideShow';

import Weather from "./components/Weather";

import Announcement from './slides/Announcement/Announcement';
import SoftSkills from "./slides/SoftSkills/SoftSkills";
import Birthday from "./slides/Birthday/Birthday";
import Image from "./slides/Image/Image";
import Video from "./slides/Video/Video";
import QuotableCorps from "./slides/QuotableCorps/QuotableCorps";
import PersonOfTheDay from "./slides/PersonOfTheDay/PersonOfTheDay";
import {isAfter} from "date-fns";
import Countdown from "./slides/Countdown/Countdown";
import {PetSlide} from "./slides/PetSlide/PetSlide";
import slide from "./components/Slide";

function App() {

    const sheetID = '1RD00VCD6DocskTtq5x8D-WMKea3CEkKyFsTG-casKew';
    const googleAPIKey = 'AIzaSyBCFPdm8U5uNP2maULZ_s1R-smZ-fN8MPA';

    const [slideData, setSlideData] = useState({});

    useEffect(() => {
       loadSlideData();

        //setup data refreshing every 15 minutes on the 15 minute mark
        const date = new Date(),
            nextInterval = new Date(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), (date.getMinutes() - (date.getMinutes() % 15)) + 15, 0, 0),
            remainingTime = nextInterval - date;
        const reloadInterval = setInterval(loadSlideData, remainingTime);
        return () => clearInterval(reloadInterval);
    }, []);

    useEffect(() => {
        if( objectIsEmpty(slideData) ){
            return;
        }
        console.log('slide data updated—if this shows up more than once at load in rapid succession, there’s a memory leak!');
    }, [slideData]);

    const loadSlideData = () => {
        const dataURLs = [
            buildURL(googleAPIKey, sheetID, 'Announcements!A:V'),
            buildURL(googleAPIKey, sheetID, 'Images!A:D'),
            buildURL(googleAPIKey, sheetID, 'Videos!A:D'),
            buildURL(googleAPIKey, sheetID, 'SoftSkills!A:W'),
            buildURL(googleAPIKey, sheetID, 'Countdown!A:F'),
            'https://corps-api.apsoprojects.org/employees/birthdays',
            'https://clients.rileypaulsen.com/bsu/corps/tvboard/quotable.php',
            'https://corps-api.apsoprojects.org/employees/random?cohort=September 2024',
            // 'https://corps-api.apsoprojects.org/employees/random',
            // 'https://zbs7c7ya7ljleaz4q3rszy2kfa0idszn.lambda-url.us-east-2.on.aws/'
        ];

        let promises = dataURLs.map(url => {
           return fetch(url).then(response=>response.json());
        });

        Promise.all(promises)
            .then(([
                announcements,
                images,
                videos,
                softSkills,
                countdowns,
                birthdays,
                quotableCorps,
                personOfTheDay,
                // pets
           ]) => {
                setSlideData({
                    announcements: parseSheetsData(announcements),
                    images: parseSheetsData(images),
                    videos: parseSheetsData(videos),
                    softSkills: parseSheetsData(softSkills),
                    countdowns: parseSheetsData(countdowns),
                    birthdays,
                    quotableCorps: quotableCorps.messages,
                    personOfTheDay: personOfTheDay,
                    // pets
                })
            });
    };

    const getSlides = position => {
        let slides = [];
        slides.push(getImageSlides(position));
        slides.push(getVideoSlides(position));
        slides.push(getAnnouncementSlides(position));
        switch(position){
            case 'top':
                break;
            case 'left':
                slides.push(getPersonOfTheDaySlide());
                slides.push(getCountdownSlides());
                // slides.push(getPetsSlide());
                break;
            case 'right':
                slides.push(getQuotableCorpsSlides());
                slides.push(getSoftSkillsSlides());
                slides.push(getBirthdaySlides());
                break;
            default:
                break;
        }
        return slides.length === 0 ? [] : randomize(slides.flat());
    }

    const getAnnouncementSlides = ( position ) => {
        const label = <>Hear Ye,<br/>Hear Ye</>;
        return arrayIsEmpty(slideData.announcements) ? [] : randomize(slideData.announcements.filter(announcement=>{
            return announcement.Position.toLowerCase() === position && isInTimeRange(startOfDay(announcement.Start), endOfDay(announcement.End));
        }).map(announcement =>
            <Announcement duration={20000} label={label} key={announcement.id} {...announcement}/>
        ));
    };

    const getSoftSkillsSlides = () => {
        const label = <>Soft<br/>Skills</>;
        return arrayIsEmpty(slideData.softSkills) ? [] : randomize(slideData.softSkills.filter(softSkill=>{
            const nextWeek = new Date(softSkill.Monday);
            //TODO the 4 below may vary between years; this should be Monday + how many days until it expires (i.e. the last session of the week)
            nextWeek.setDate(nextWeek.getDate() + 4);
            return isInTimeRange(softSkill.Monday, nextWeek);
        }).map(softSkill =>
            <SoftSkills duration={10000} label={label} key={softSkill.Title} {...softSkill}/>
        ));
    };

    const getBirthdaySlides = () => {
        const label = <>Party<br/>Time</>;
        return arrayIsEmpty(slideData.birthdays) ? [] : randomize(slideData.birthdays.filter(birthday=>{
            const today = getToday();
            return birthday.birthday.month === today.getMonth() + 1 && birthday.birthday.day === today.getDate();
        }).map(birthday =>
            <Birthday duration={12000} label={label} key={birthday.id} {...birthday}/>
        ));
    };

    const getPersonOfTheDaySlide = () => {
        const person = Array.isArray(slideData.personOfTheDay) ? slideData.personOfTheDay[0] : slideData.personOfTheDay;

        const label = <>Meet a<br/>Co-worker</>;
        const sectionDuration = 10*1000;
        const sections = ['bio'];
        if( person.hometown ) { sections.push('hometown'); }
        if( person.top_5_clifton_strengths ) { sections.push('strengths'); }
        const duration = sectionDuration * sections.length;
        return objectIsEmpty(person) ? [] : [<PersonOfTheDay googleAPIKey={googleAPIKey} label={label} sectionDuration={sectionDuration} duration={duration} person={person} />];
    };

    const getImageSlides = position => {
      return arrayIsEmpty(slideData.images) ? [] : slideData.images.filter(image=>{
          return image.Position.toLowerCase() === position && isInTimeRange(startOfDay(image.Start), endOfDay(image.Expiration))
      }).map(image =>
          <Image duration={10000} key={image.URL.substr(9,20)} src={image.URL}/>
      );
    };

    const getCountdownSlides = () => {
        const countdownSlides = slideData.countdowns.filter(countdown=>{
            const timeParts = countdown.Time.split(':');
            //adds the time to the date, but adds one day to account for timezone offset being back one day
            const endDateTime = new Date(countdown.Date.getFullYear(), countdown.Date.getMonth(), countdown.Date.getDate() + 1, timeParts[0], timeParts[1]);

            return !isAfter(new Date(), endDateTime);
        }).map(countdown => {
            return <Countdown key={countdown['Event Title']} duration={10000} countdown={countdown} />
        });
        return countdownSlides.length > 0 ? countdownSlides : [];
    }

    const getVideoSlides = position => {
      return arrayIsEmpty(slideData.videos) ? [] : slideData.videos.filter(video=>{
          if( !video.Position ){
              return false;
          }
          return video.Position.toLowerCase() === position && isInTimeRange(startOfDay(video.Start), endOfDay(video.Expiration))
      }).map(video => {
            return <Video key={video.URL.substring(9, 29)} src={video.URL} />
          }
      );
    };

    const getQuotableCorpsSlides = () =>{
        const label = <>Quotable<br/>Corps</>;
        return arrayIsEmpty(slideData.quotableCorps) ? [] :
            slideData.quotableCorps
                .filter(message => !message.text.includes('has joined the channel'))
                .map(message => {
                    return <QuotableCorps label={label} key={message.ts} message={message.text} />
                });
    };
    const getPetsSlide = () =>{
        const label = <>The Pet Corps!</>
        const duration = 10000;
        return(Object.keys(slideData.pets).map((key)=>{
            return <PetSlide label={label} duration={duration} text={slideData.pets[key].message} image={slideData.pets[key].link}></PetSlide>
        }))
    }

    const params = new URLSearchParams(window.location.search);

    return objectIsEmpty(slideData) ? null : (
    <div className="App">
      <div className="display">
          <SlideShow className="main" slides={getSlides('top')}/>
          <SlideShow className="left" slides={getSlides('left')}/>
          <SlideShow className="right" slides={getSlides('right')}/>
          {!params.has('preview') && !window.location.href.includes('localhost') && <Weather layoutPos="bottom"/>}
      </div>
    </div>
  );
}

export default App;
